<script>
import VideoWidget from "@components/elements/video-widget.vue";

export default {
    name: "DataPresentation",
    components: {
        VideoWidget,
    },
    data() {
        return {
            videoData: {
                id: 'geoeffect-video',
                poster: '/assets/images/landing/transparent.png',
                sources: [
                    {
                        src: '/assets/files/geoeffect.mp4',
                        type: 'video/mp4;codecs="avc1.42E01E, mp4a.40.2"',
                    },
                    {
                        src: '/assets/files/geoeffect.webm',
                        type: 'video/webm; codecs="vorbis,vp8"',
                    },
                ],
            },
            slides: [
                '/assets/images/slider/tourism.png',
                '/assets/images/slider/summary_data.png',
                '/assets/images/slider/portrait.png',
                '/assets/images/slider/tourist_flow.png',
                '/assets/images/slider/tourist_flow_2.png',
            ]
        }
    }
}
</script>

<template>
    <div class="data-presentation animate-item" id="people">
        <div class="data-presentation-content">
            <div class="block-header data-presentation-content-header">
                <h2
                    class="data-presentation-title"
                >
                    Качественные, охватные и&nbsp;оперативные данные
                </h2>
                <p>Визуализация динамики численности, портрета, плотности <br>и поведенческих характеристик</p>
            </div>
            <div class="block-content animated">
                <div class="data-presentation-scrollable">
                    <div class="presentation-items">
                    <div class="block-item">
                        <div class="block-item-content">
                            <h3>Население</h3>
                            <p>Численность и социально-демографический портрет</p>
                        </div>
                    </div>
                    <div class="block-item">
                        <div class="block-item-content">
                            <h3>Объекты</h3>
                            <p>Посещаемость и популярность <br>локаций</p>
                        </div>
                    </div>
                    <div class="block-item">
                        <div class="block-item-content">
                            <h3>Туристы</h3>
                            <p>Портрет, характеристики <br> поездок и их длительность</p>
                        </div>
                    </div>
                    <div class="block-item">
                        <div class="block-item-content">
                            <h3>Доступность объектов</h3>
                            <p>Пешеходная и транспортная <br> доступность инфраструктуры</p>
                        </div>
                    </div>
                    <div class="block-item">
                        <div class="block-item-content">
                            <h3>Тепловая карта</h3>
                            <p>Распределение плотности <br> населения или туристов</p>
                        </div>
                    </div>
                </div>
                </div>
                <VideoWidget
                    :id="videoData.id"
                    :poster="videoData.poster"
                    :sources="videoData.sources"
                />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/sections/data-presentation.scss';
</style>