<script>
import {dataLayerEvent} from "@utils/data-layer";

export default {
    name: "MainScreen",
    methods: {
        openDigitalB2BEvent() {
            dataLayerEvent({
                eventId: 6,
                event: 'vntGeo',
                eventCategory: 'glavnaya',
                eventLabel: 'poprobovat',
                eventAction: 'button_click'
            });
        }
    }
}
</script>

<template>
    <div class="main-screen animate-item">
        <div class="main-screen-content main-screen-content-home-page main-screen-full-map">
            <div class="presentation-bg"></div>
            <div class="text-block-wrap">
                <div class="text-block">
                    <h1>Геоэффект</h1>
                    <p>Геоэффект — сервис для геоаналитики и геомаркетинга для бизнеса.<br>
                        Воспользуйтесь нашим продуктом по оценке и анализу локаций онлайн. Изучайте аудиторию и делайте выбор на основе геоданных о населении и турпотоке!</p>
                </div>
                <div class="text-footer-block main-screen-button">
                    <button
                        class="btn"
                        data-product="GeoEffect"
                        data-vitrine="Геоэффект"
                        data-btn-view="hidden"
                        data-bo-hw-lead-button="true"
                        data-bo-required-inn="true"
                        data-bo-not-required="email"
                        data-bo-subline=""
                        data-btn-text="Попробовать"
                        @click="openDigitalB2BEvent"
                    >
                        <img src="https://digitalb2b.mts.ru/widget/static/formPopup.skeleton.svg" alt="Загрузка" width="270" height="52" class="bo-widget-skeleton-btn"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/sections/main-screen.scss';
</style>