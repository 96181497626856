<script>
import { dataLayerEvent } from '@utils/data-layer.js';

export default {
    name: "VideoWidget",
    props: {
        id: {
            type: String,
            default: ""
        },
        poster: {
            type: String,
            default: ""
        },
        sources: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            videoControls: false,
        }
    },
    mounted() {
        Number.prototype.lead0 = function (n) {
            var nz = "" + this;
            while (nz.length < n) {
                nz = "0" + nz;
            }
            return nz;
        };

        const controls = {
            video: document.getElementById(this.id),
            play: document.getElementById(`${this.id}-playpause`),
            pause: document.getElementById(`${this.id}-playpause-controls-playpause`),
            buffered: document.getElementById(`${this.id}-buffered`),
            progress: document.getElementById(`${this.id}-progress`),
            progressCurrent: document.getElementById(`${this.id}-progress-current`),
            total: document.getElementById(`${this.id}-total`),
            current: document.getElementById(`${this.id}-currenttime`),
            hasHours: false,
        };

        const video = document.getElementById(this.id);

        const formatTime = (time, hours) => this.formatTime(time, hours);
        const totalWidth = controls.total.getClientRects()[0].width;

        video.addEventListener("canplay", () => {
            controls.hasHours = (video.duration / 3600) >= 1.0;
            controls.duration = formatTime(video.duration, controls.hasHours);
            controls.current.innerText = formatTime(0, controls.hasHours);
        });

        video.addEventListener("timeupdate", () => {
            controls.current.innerText = formatTime(video.currentTime, controls.hasHours);
            const progress = Math.floor(video.currentTime) / Math.floor(video.duration);
            controls.progressCurrent.style.width = Math.floor(progress * totalWidth) + "px";
        });

        video.addEventListener('ended', () => {
            this.videoControls = !this.videoControls;

            controls.current.innerText = formatTime(video.currentTime, controls.hasHours);
            const progress = Math.floor(video.currentTime) / Math.floor(video.duration);
            controls.progressCurrent.style.width = Math.floor(progress * totalWidth) + "px";
        })
    },
    methods: {
        formatTime(time, hours) {
            if (hours) {
                const h = Math.floor(time / 3600);
                const time = time - h * 3600;

                const m = Math.floor(time / 60);
                const s = Math.floor(time % 60);

                return h.lead0(2) + ":" + m.lead0(2) + ":" + s.lead0(2);
            } else {
                const m = Math.floor(time / 60);
                const s = Math.floor(time % 60);

                return m.lead0(2) + ":" + s.lead0(2);
            }
        },
        playpauseEvent() {
            const video = document.getElementById(this.id);

            if (video.paused) {
                video.play();
            } else {
                video.pause();
            }

            this.videoControls = !this.videoControls;

            dataLayerEvent({
                eventId: 8,
                event: 'vntGeo',
                eventCategory: 'glavnaya',
                eventLabel: 'play',
                eventAction: 'element_click'
            });
        },
    },
}
</script>

<template>
    <div class="video-block">
        <div class="video-block-wrapper">
            <video
                @click="playpauseEvent"
                :id="id"
                :poster="poster">
                <source
                    v-for="(elem, i) in sources"
                    :key="i"
                    :src="elem.src"
                    :type='elem.type'
                />
            </video>

            <div :id="`${id}-playpause`"
                 class="playpause"
                 :class="{ 'hidden': videoControls }"
                 @click="playpauseEvent">
                <div class="icon icon-play"></div>
            </div>

            <div id="geoeffect-controls"
                 class="geoeffect-controls"
                 :class="{ 'hidden': !videoControls }">

                <div class="geoeffect-controls-wrap">
                    <div :id="`${id}-playpause-controls-playpause`"
                         class="controls-playpause"
                         @click="playpauseEvent">
                        <div class="icon icon-pause"></div>
                    </div>

                    <div :id="`${id}-progress`" class="geoeffect-progress">
                        <div :id="`${id}-total`" class="geoeffect-total">
                            <div :id="`${id}-buffered`" class="geoeffect-buffered">
                                <div :id="`${id}-progress-current`" class="geoeffect-current"></div>
                            </div>
                        </div>
                    </div>

                    <div id="geoeffect-time" class="geoeffect-time">
                        <div :id="`${this.id}-currenttime`" class="geoeffect-currenttime">00:00</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@design/elements/video-block.scss';
</style>